<template>
  <div class="home" :class="{ 'scroll-lock': itemSwitchShow }">
    <Header :info="states.groupInfo" />
    <div v-if="states.eventList.length" class="event-section">
      <DecoTitle title="主題精選" />
      <EventSwiper class="event-swiper" :eventList="states.eventList" />
    </div>
    <div class="store-list">
      <h3 class="title">飯店精選</h3>
      <ShowCard
        v-for="(store, index) in states.hotelList"
        :key="'store' + index"
        :info="store"
        @click="toStore(store)"
      />
    </div>
    <div class="fixed fixedBottom w-full text-center">
      <div @click="openReservationSwiper" class="reservation">
        線上訂位/訂餐
      </div>
    </div>
    <ItemSwitch
      class="item-switch"
      :class="{ 'item-switch-show': itemSwitchShow }"
      :show="itemSwitchShow"
      :title="itemSwitchTitle"
      :itemList="restaurantList"
      @handle-click="closeItemSwitch"
    />
    <ReservationSwiper
      class="reservation-swiper"
      :initHotelList="initHotelList"
      @handleClick="openItemSwitch"
      :active="active"
    />
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import DecoTitle from '@/components/decoTitle.vue'
import EventSwiper from '@/components/eventSwiper.vue'
import ReservationSwiper from '@/components/reservationSwiper.vue'
import ItemSwitch from '@/components/itemSwitch.vue'
import ShowCard from '@/components/showCard.vue'
import router from '@/router'
import api from '@/api/api'
import { ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Home',
  components: {
    Header,
    EventSwiper,
    ReservationSwiper,
    DecoTitle,
    ShowCard,
    ItemSwitch,
  },
  setup() {
    const store = useStore()
    window.inlineAuthTokenCallback = value => {
      store.commit('m_setInleinTokenInfo', value)
    }

    if (typeof window.host_getInlineAuthToken !== 'undefined') {
      window.host_getInlineAuthToken('inlineAuthTokenCallback')
    }

    api
      .ActivityList({
        hotelId: '',
        restaurantId: '',
        pageType: 3,
      })
      .then(result => {
        store.commit('m_setEventList', result.data.slice(0, 7))
      })
      .catch(err => {
        console.error('取得活動失敗', err)
      })

    const initHotelList = ref([])
    api
      .InitializeInfo({ deviceType: 3 })
      .then(result => {
        initHotelList.value = result.data.hotelList
      })
      .catch(err => {
        console.error('資訊初始失敗', err)
      })

    const states = reactive({
      groupInfo: computed(() => store.state.groupInfo),
      hotelList: computed(() => store.state.hotelList),
      eventList: computed(() => store.state.eventList),
    })
    function toStore(info) {
      // eslint-disable-next-line
      gtag('event', 'click', {
        event_id: '002',
        event_name: '飯店精選',
        location: '首頁-集團介紹',
        event_category: `飯店精選--${info.title}`,
        event_label: info.title,
        event_value: 2,
      })
      router.push({ name: 'Store', params: { sid: info.id } })
    }
    let active = ref(false)
    function openReservationSwiper() {
      active.value = !active.value
    }

    const itemSwitchShow = ref(false)
    const itemSwitchTitle = ref('')
    const restaurantList = ref([])
    const openItemSwitch = value => {
      api
        .RestaurantList({
          hotelId: value.id,
        })
        .then(result => {
          itemSwitchShow.value = value.status
          itemSwitchTitle.value = value.name
          restaurantList.value = result.data.filter(item => {
            return item.reservationUrl ? item : null
          })
        })
        .catch(err => {
          console.error('取得餐廳失敗', err)
        })
    }
    const closeItemSwitch = value => {
      itemSwitchShow.value = value
    }
    return {
      states,
      itemSwitchShow,
      itemSwitchTitle,
      restaurantList,
      toStore,
      openReservationSwiper,
      openItemSwitch,
      closeItemSwitch,
      active,
      initHotelList,
    }
  },
}
</script>

<style scoped lang="scss">
.home {
  &.scroll-lock {
    height: 100vw;
    overflow: hidden;
  }
}

.event-section {
  background: #fffdfc;
  box-shadow: 0px 4px 16px #ede9db;
}
.store-list {
  padding: 32px 5.33% 99px;
  .title {
    padding-bottom: 17px;
  }
}
.fixedBottom {
  bottom: 10px;
}
.event-swiper {
  position: relative;
  z-index: 0;
}
.reservation {
  display: inline-block;
  padding: 9px 32px;
  background: rgba(206, 177, 125, 0.92);
  backdrop-filter: blur(40px);
  border-radius: 100px;
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: -0.32px;
  line-height: 24px;
  z-index: 9;
}
.item-switch {
  position: fixed;
  z-index: 20;
  bottom: -600px;
  transition: bottom 0.5s ease;

  &.item-switch-show {
    bottom: 0px;
  }
}
.reservation-swiper {
  position: relative;
  z-index: 10;
}
</style>
