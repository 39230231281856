<template>
  <div class="reservation-swiper">
    <div v-if="innerActive" @click="closeOverlay" class="overlay"></div>
    <div class="swiper-outter" :class="{ active: innerActive }">
      <swiper
        :slides-per-view="1"
        :space-between="10"
        :loop="false"
        :slidesOffsetBefore="30"
        :width="swiperWidth"
      >
        <swiper-slide
          v-for="(hotel, index) in innerHotelList"
          :key="'event' + index"
        >
          <div class="reservation-card">
            <div class="logo">
              <img :src="hotel.logoUrl" />
            </div>
            <div class="reservation-content">
              <div class="name">{{ hotel.name }}</div>
              <div class="address">{{ hotel.address }}</div>
              <div class="button-box">
                <div @click="openUrl(hotel.bookingUrl)">住宿訂房</div>
                <div @click="openUrl(hotel.reservationUrl, index)">
                  餐廳訂位/訂餐
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed, watch } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

export default {
  name: 'ReservationSwiper',
  components: { Swiper, SwiperSlide },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    initHotelList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {},
  emits: ['handle-click'],
  setup(props, { emit }) {
    const outterActive = computed(() => {
      return props.active
    })
    let innerActive = ref(false)
    watch(outterActive, () => {
      innerActive.value = outterActive
    })

    const innerHotelList = computed(() => {
      return props.initHotelList
    })

    let swiperWidth = ref(0)
    onMounted(() => {
      const vw = document.querySelector('.reservation-swiper').clientWidth
      swiperWidth.value = vw * 0.84
    })
    window.onresize = () => {
      const vw = document.querySelector('.reservation-swiper').clientWidth
      swiperWidth.value = vw * 0.84
    }

    function closeOverlay() {
      innerActive.value = false
    }

    function openUrl(url, index = 0) {
      const name = innerHotelList.value[index].name
      const id = innerHotelList.value[index].id
      if (url.includes('inline')) {
        emit('handle-click', { status: true, id, name })
      } else {
        window.openUrlExternal(url)
      }
    }
    return {
      swiperWidth,
      closeOverlay,
      openUrl,
      innerActive,
      innerHotelList,
    }
  },
}
</script>

<style scoped lang="scss">
.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  top: 0;
}

::v-deep(.swiper-container) {
  overflow: visible !important;
}

.swiper-outter {
  position: fixed;
  bottom: -300px;
  transition: bottom 0.5s ease;
  &.active {
    bottom: 24px;
  }
}

.reservation-card {
  height: 220px;
  background-color: #fefdfb;
  border-radius: 8px;
  .logo {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0px 3px 10px 4px rgba(36, 36, 36, 0.1);
    -webkit-box-shadow: 0px 3px 10px 4px rgba(36, 36, 36, 0.1);
    -moz-box-shadow: 0px 3px 10px 4px rgba(36, 36, 36, 0.1);
    -o-box-shadow: 0px 3px 10px 4px rgba(36, 36, 36, 0.1);
    position: relative;
    left: calc(50% - 36px);
    top: -24px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .reservation-content {
    padding: 0px 20px 24px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    top: -10px;
    .name {
      height: 24px;
      line-height: 24px;
      font-size: 15px;
      font-weight: bold;
      color: #383838;
    }
    .address {
      font-size: 13px;
      height: 18px;
      line-height: 18px;
      color: #545454;
      margin-top: 5px;
    }
    .button-box {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      width: 100%;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc((100% - 16px) / 2);
        height: 44px;
        background: #244c5a;
        box-shadow: 4px 4px 8px rgba(56, 97, 91, 0.2);
        border-radius: 1px;
        color: #ffffff;
        font-size: 16px;
      }
      div ~ div {
        margin-left: 8px;
      }
    }
  }
}
@media (max-width: 340px) {
  .reservation-card {
    .reservation-content {
      .button-box {
        div {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
